<template>
  <layout-stepper>
    <template #body>
      <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
          <b-col class="col-12 text-center mb-3">
            <h1 class="mb-1">{{ $t('creator.findBrand') }}</h1>
            <p class="mb-2">{{ $t('creator.fromCastingCall') }}</p>

            <div class="d-flex flex-wrap align-items-center justify-content-center" v-if="!is_adding_brand">
             <vue-autosuggest
              :suggestions="[{data: brands}]"
              :limit="10"
              :input-props="input_locations"
              @input="inputBrand"
              v-model="brand"
              :get-suggestion-value="selectBrand"
              :should-render-suggestions="shouldRenderSuggestions"
              @keyup.enter="brand.length > 0 ? save(): ''"
              class="w-100 mb-2"
              > 
                <template slot-scope="{suggestion}">
                  <div class="d-flex justify-content-between cursor-pointer">
                    <div class="d-flex align-items-center">
                      <b-avatar icon="shop" :src="logoImage(suggestion.item)" size="27" class="mr-1"></b-avatar>
                      <span class="my-suggestion-item text-left">{{ suggestion.item.name }}</span>
                    </div>
                    <div>
                      {{suggestion.item.domain}}
                    </div>
                  </div>
                </template>

                <template slot="before-suggestions">
                  <div class="m-1" v-if="brand !== ''">
                    {{$t("search.searchingBy")}}:
                  </div>
                  <hr>
                </template>
                <template slot="after-suggestions">
                  <div class="m-1" v-if="brands.legth === 0">
                    {{$t("search.noResults")}}
                  </div>
                </template>
              </vue-autosuggest>
              <div class="">
                <p class="m-0">{{ $t('creator.cantBrand?') }}</p>
                <b-link @click="is_adding_brand = true">{{ $t('creator.addBrand') }}</b-link>
              </div>
            </div>
            <div v-else class="form-add-brand-step">
              
              <div class="container-custom-input-file mb-2" v-if="!file_blob">
                <div class="container-file">
                  <div class="mb-1"><span class="text-blue"><feather-icon icon="ShareIcon" class="mr-03"></feather-icon>Seleccionar</span> o arrastrar Logo aquí</div>
                  <p class="mb-0">{{$t('creator.anyLogoSelected')}}</p>
                </div>
                <b-form-file 
                  class="custom-input" 
                  v-model="file" 
                  accept=".jpeg, .jpg, .png" 
                  @input="hasChangeFile"
                >
                </b-form-file>
              </div>
              <div class="container-blob mb-1" v-else>
                <div class="container-trash-blob" @click="file_blob = null; file = null"> 
                  <feather-icon icon="TrashIcon" class="icon-trash-blob"></feather-icon>
                </div>
                <b-img :src="file_blob" class="img-blob"></b-img>
              </div>

              <!-- <b-form-group label="Logo" label-for="input-file" class="form-group-layout"> -->
                <!-- <b-form-file></b-form-file> -->
              <!-- </b-form-group> -->
              <b-form-group :label="$t('creator.nameOrganization')" label-for="input-name" class="form-group-layout">
                <b-form-input id="input-name" v-model="name" :state="name.length > 0" @keyup.enter="isPassingValidations() ? saveBrand(): ''"></b-form-input>
              </b-form-group>

              <b-form-group :label="$t('creator.domainOrganization')" label-for="input-domain" class="form-group-layout">
                <b-form-input :state="urlStateExpression(domain)" id="input-domain" @keyup.enter="isPassingValidations() ? saveBrand(): ''" v-model="domain" type="url"></b-form-input>
                <b-form-invalid-feedback id="invalid-feedback-url">
                  {{ $t('creator.enterUrl') }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-link @click="is_adding_brand = false; clearForm()" class="d-block mb-1 link-back">{{ $t('creator.switchSearch') }}</b-link>
              <b-button :disabled="!isPassingValidations()" variant="blue-button-next-step" class="blue-button-next-step" @click="saveBrand">{{ $t('creator.save') }}</b-button>
            </div>
          </b-col>
          <b-col class="col-12 d-flex justify-content-center" v-if="!is_adding_brand">
            <b-button variant="blue-button-next-step" class="blue-button-next-step" @click="save()">{{ $t('creator.save') }}</b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BAvatar,
  BLink,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BImg,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/proposals/layoutStepper.vue';
import { VueAutosuggest } from 'vue-autosuggest';
import casting_calls_services from '@/services/casting_calls';
import { isUrl, getAssetUrl, urlStateExpression } from '@/libs/utils/urls'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');


export default {
  name: 'stepOrganization',
  components: {
    BRow,
    BFormInvalidFeedback,
    BCol,
    BButton,
    BFormFile,
    BFormGroup,
    BFormInput,
    BAvatar,
    BLink,
    layoutStepper,
    BImg,
    VueAutosuggest,
  },
  data() {
    return {
      isUrl,
      getAssetUrl,
      urlStateExpression,
      brands: [],
      brand: '',
      brand_selected: null,
      is_adding_brand: true,
      input_locations: {
        class: 'form-control',
        placeholder: this.$t('creator.brandUrl')
      },
      file: null,
      file_blob: null,
      name: '',
      domain: 'https://',
      steps: null,
    }
  },
  created() {
    this.steps = JSON.parse(localStorage.getItem('steps'));
    this.getBrands('');
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    }
  },
  methods: {
    logoImage(item) {
      if (item.logo_url && item.logo_url.length > 0) {
        return isUrl(item.logo_url) ? item.logo_url : getAssetUrl(item.logo_url)
      } else {
        return isUrl(item.logo) ? item.logo : getAssetUrl(item.logo)
      }
    },
    hasChangeFile() {
      if (this.file) {
        setTimeout(() => {
          this.file_blob = URL.createObjectURL(this.file);
        },200)
      }
    },
    isPassingValidations() {
      const is_passing_all = [];
      is_passing_all.push(urlStateExpression(this.domain))
      is_passing_all.push(!!this.file)
      is_passing_all.push(this.name.length > 0)
      return is_passing_all.every((item) => item === true);
    },
    shouldRenderSuggestions (size, loading) {
      return size >= 0 && !loading
    },
    selectBrand(value) {
      this.brand = value.item.name;
      this.brand_selected = value.item;
    },
    clearForm() {
      this.file = null;
      this.name = null;
      this.domain = null;
    },
    inputBrand(value) {
      this.getBrands(value)
    },
    getBrands(query) {
      casting_calls_services.getBrands(query).then((response) => {
        this.brands = response.results;
      });
    },
    saveBrand() {
      const form_data = new FormData();
      form_data.append('name', this.name);
      form_data.append('domain', this.domain);
      form_data.append('logo', this.file);
      casting_calls_services.createBrand(form_data)
        .then((response) => {
          if (response.data && response.data.response && response.data.response.code && response.data.response.code === "brand.domainAlreadyExists") {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t('creator.error_domain'),
                  text: this.$t('creator.error_domain_again'),
                  icon: "AlertCircleIcon",
                  variant: "danger",
                }
              })
            })
          } else {
            this.steps['brand'] = response;
            localStorage.setItem('steps', JSON.stringify(this.steps));
            this.$router.push({
              name: 'steps_casting_call',
              params: {
                step: 'organization',
                slug: this.has_slug
              }
            });
            this.clearForm();
          }
        });
    },
    save() {
      (this.has_slug) ? this.steps = {brand: this.brand_selected} : this.steps['brand'] = this.brand_selected;
      localStorage.setItem('steps', JSON.stringify(this.steps));
      this.$router.push({
        name: 'steps_casting_call',
        params: {
          step: 'organization',
          slug: this.has_slug
        }
      });
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.custom-input {
  .custom-file-label {
    border: 1px dashed #3483fa !important;
    cursor: pointer;
    color: transparent;
    position: absolute;
    height: 130px !important;
    top: 0px;
    padding: 1em;
    background: transparent !important;
    border-radius: 1em;
    @media(max-width: 450px) {  
      height: 160px;
    }

    &:hover {
      border: 2px solid #3483fa !important;
      cursor: pointer;
    }

    &::after {
      display: none;
    }
  }
}

@media(max-width: 450px) {
  .custom-input .custom-file-label, .container-custom-input-file, .container-file  {
    height: 250px !important;
  }
}
</style>
<style>
.form-add-brand-step label {
  text-align: left !important;
}
</style>
<style lang="scss" scoped>
.text-blue {
  color: #3483fa;
}
.mr-03 {
  margin-right: 0.3em;
}
.container-custom-input-file {
  position: relative;
  height: 130px;
  .container-file {
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgba(0,0,0,.45);
    height: 130px;
    font-size: 12px;
    cursor: pointer;
    padding: 1em;
  }
}
.container-blob {
  height: 130px;
  background-color: #DEE2E6;
  border-radius: 0.7em;
  position: relative;
  
  .container-trash-blob {
    position: absolute;
    top: 0.5em;
    right: 0.5em;     
    cursor: pointer;
    padding: 0.6em;
    border-radius: 10em;
    
    &:hover {
      background-color: #bdc0c4da;
    }
    .icon-trash-blob {
      color: black;
    }
  }
  .img-blob {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
<template>
  <layout-stepper>
    <template #body>
      <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">{{ $t('creator.addTag') }}</h1>
            <p class="mb-2">{{ $t('creator.tagPotential') }}</p>
            <add-tags-component :casting_call="casting_call"></add-tags-component>
            <b-col class="col-12 d-flex justify-content-center">
              <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()">{{has_slug ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
            </b-col>
          </b-col>
          <small class="mt-2 w-100 text-center text-muted">{{ $t('creator.tagOptional') }}</small>
        </b-row>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/proposals/layoutStepper.vue';
import casting_calls_services from '@/services/casting_calls';

export default {
  name: 'stepTags',
  components: {
    BRow,
    BCol,
    BButton,
    layoutStepper,
    AddTagsComponent: () => import('@/views/components/inputs/AddTagsComponent.vue')
  },
  data() {
    return {
      profile: {},
      steps: null,
      casting_call: {},
      casting_call_uuid: '',
    }
  },
  created() {
    this.getData();
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    }
  },
  methods: {
    getData() {
      if (this.has_slug) {
        this.casting_call = this.$route.params.casting_call_data;
        this.casting_call_uuid = this.casting_call.uuid;
        if (this.casting_call.tags === null) this.casting_call.tags = [];

      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        this.casting_call_uuid = this.steps.uuid ? this.steps.uuid : this.$route.params.casting_call_uuid
        casting_calls_services.getCastingCall(this.casting_call_uuid).then((response) => {
          this.casting_call = response;
          if (this.casting_call.tags === null) this.casting_call.tags = [];
        });
      }
    },
    save() {
      const form_data = new FormData();
      this.casting_call.tags.forEach((t) => {
        form_data.append('tags', t);
      })
      if (this.casting_call.tags.length === 0) form_data.append('remove_tags', true)
      casting_calls_services.updateCastingCall(this.casting_call_uuid, form_data).then((response) => {
        if (response.status === 'OK' && !this.has_slug) this.$router.push({ name: 'steps_casting_call', params: {step: 'attachment-files'}})
        else this.$router.push({name: 'preview_casting_call', params: {slug: this.casting_call.slug}});
      })
    },
  }
}
</script>

<template>
  <layout-stepper :key="current_step">
    <template #body>
      <div class="d-flex">
        <b-col class="col-6 text-start">
          <b-link @click="makeRedirect()" v-if="has_prev_step" class="link-back">
            <feather-icon icon="ChevronLeftIcon"></feather-icon>
            {{ $t('creator.previousStep') }}
          </b-link>
        </b-col>
      </div>
      <div>
        <step-select-service v-if="current_step === 'select-service'"/>
        <step-service-options v-if="current_step === 'service-options'"/>
        <step-organization v-if="current_step === 'organization'"/>
        <step-organization-search v-if="current_step === 'organization-search'"/>
        <step-title v-if="current_step === 'title-casting-call'"/>
        <step-cover-image v-if="current_step === 'cover-image'"/>
        <step-preview-card v-if="current_step === 'preview-card'"/>
        <step-description v-if="current_step === 'description-casting-call'"/>
        <step-visibility v-if="current_step === 'visibility'"/>
        <step-location v-if="current_step === 'location'"/>
        <step-delivery-deadline v-if="current_step === 'delivery-deadline'"/>
        <step-tags v-if="current_step === 'tags'"/>
        <step-attachment-files v-if="current_step === 'attachment-files'"/>
        <step-compensation v-if="current_step === 'compensation'"/>
        <step-information-request v-if="current_step === 'information-request'"/>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BLink,
  BCol,
} from 'bootstrap-vue';

import layoutStepper from '@/views/components/proposals/layoutStepper.vue';
import stepSelectService from './stepSelectService.vue';
import stepServiceOptions from './stepServiceOptions.vue';
import stepOrganization from './stepOrganization.vue';
import stepTitle from './stepTitle.vue';
import stepCoverImage from './stepCoverImage.vue';
import stepVisibility from './stepVisibility.vue';
import stepDescription from './stepDescription.vue';
import stepDeliveryDeadline from './stepDeliveryDeadline.vue';
import stepTags from './stepTags.vue';
import stepAttachmentFiles from './stepAttachmentFiles.vue';
import stepCompensation from './stepCompensation.vue';
import stepInformationRequest from './stepInformationRequest.vue';
import stepOrganizationSearch from './stepOrganizationSearch.vue';
import StepPreviewCard from './stepPreviewCard.vue';

export default {
  name: 'settingCastingCallDashboard',
  components: {
    BLink,
    BCol,
    stepSelectService,
    stepServiceOptions,
    stepOrganization,
    stepTitle,
    stepCoverImage,
    stepVisibility,
    stepDescription,
    stepDeliveryDeadline,
    stepTags,
    stepAttachmentFiles,
    stepCompensation,
    stepInformationRequest,
    layoutStepper,
    stepOrganizationSearch,
    StepPreviewCard,
    stepLocation: () => import('./stepLocation.vue')
  },
  data() {
    return {
      has_prev_step: false,
      current_step: this.$route.params.step,
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
  },
  methods: {
    makeRedirect() {
      if (this.current_step === 'service-options') this.$router.push({ name: 'steps_casting_call', params: {step: 'select-service'}})
      else if (this.current_step === 'organization') this.$router.push({ name: 'steps_casting_call', params: {step: 'service-options'}})
      else if (this.current_step === 'organization-search') this.$router.push({ name: 'steps_casting_call', params: {step: 'organization'}})
      else if (this.current_step === 'title-casting-call') this.$router.push({ name: 'steps_casting_call', params: {step: 'organization'}})
      else if (this.current_step === 'cover-image') this.$router.push({ name: 'steps_casting_call', params: {step: 'title-casting-call'}})
      else if (this.current_step === 'preview-card') this.$router.push({ name: 'steps_casting_call', params: {step: 'cover-image'}})
      else if (this.current_step === 'description-casting-call') this.$router.push({ name: 'steps_casting_call', params: {step: 'preview-card'}})
      else if (this.current_step === 'visibility') this.$router.push({ name: 'steps_casting_call', params: {step: 'description-casting-call'}})
      else if (this.current_step === 'location') this.$router.push({ name: 'steps_casting_call', params: {step: 'visibility'}})
      else if (this.current_step === 'delivery-deadline') this.$router.push({ name: 'steps_casting_call', params: {step: 'location'}})
      else if (this.current_step === 'tags') this.$router.push({ name: 'steps_casting_call', params: {step: 'delivery-deadline'}})
      else if (this.current_step === 'attachment-files') this.$router.push({ name: 'steps_casting_call', params: {step: 'tags'}})
      else if (this.current_step === 'compensation') this.$router.push({ name: 'steps_casting_call', params: {step: 'attachment-files'}})
      else if (this.current_step === 'information-request') this.$router.push({ name: 'steps_casting_call', params: {step: 'compensation'}})
    },
  },
  watch: {
    '$route.params.step'(new_step) {
      this.current_step = new_step
      if (new_step !== 'select-service') this.has_prev_step = true
      else this.has_prev_step = false
    }
  }
}
</script>
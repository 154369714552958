<template>
  <layout-stepper>
    <template #body>
        <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">{{$t('creator.reviewLogo')}}</h1>
            <p>{{$t('creator.noEdit')}}</p>
            <b-col class="col-12 mt-2 d-flex justify-content-center">
              <card-casting-call :key="casting_call.uuid" :casting_call="casting_call" v-if="Object.keys(casting_call).length > 0"></card-casting-call>
            </b-col>
            <b-col class="mt-1 d-flex flex-column align-items-center">
              <span class="text-center">{{$t('creator.needUpdated')}}</span>
              <b-link class="mt-1 text-center link-back" @click="updateLogo()">{{$t('creator.searchBrand')}}</b-link>
            </b-col>
            <b-col class="col-12 d-flex justify-content-center">
              <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()">{{$t('creator.butonNext')}}</b-button>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </template>
  </layout-stepper>
</template>


<script>

import {
  BButton,
  BRow,
  BCol,
  BLink,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/proposals/layoutStepper.vue';
import casting_calls_services from '@/services/casting_calls'
import cardCastingCall from '@/views/components/casting-calls/cardCastingCall.vue';


export default {
  name: 'stepCompensation',
  components: {
    cardCastingCall,
    layoutStepper,
    BButton,
    BRow,
    BCol,
    BLink
  },
  data() {
    return {
      casting_call: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    updateLogo() {
      this.$router.push({ name: 'steps_casting_call', params: {step: 'organization'}})
    },
    getData() {
      this.steps = JSON.parse(localStorage.getItem('steps'));
      this.casting_call_uuid = this.steps.uuid ? this.steps.uuid : this.$route.params.casting_call_uuid
      casting_calls_services.getCastingCall(this.casting_call_uuid).then((response) => {
        this.casting_call = response;
      });
    },
    save() {
      this.$router.push({ name: 'steps_casting_call', params: {step: 'description-casting-call'}})
    },
  }
};
</script>


<template>
  <layout-stepper>
    <template #body>
      <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">{{ $t('creator.typeService') }}</h1>
            <p>{{ $t('creator.chooseService') }}</p>
          </b-col>
          <b-col
            class="col-12 mt-1" 
            v-for="(i, index) in $t('creator.type_of_service')" 
            :key="index"
            ref="card_radio_button"
            @click="getActive(i, index)"
          > 
            <card-radio-button :text="i.text" :second_text="i.subtitle" :icon_svg="i.icon_svg"></card-radio-button>
          </b-col>
          <b-col class="col-12 d-flex justify-content-center">
            <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" :disabled="selected === null" @click="save()">{{has_slug ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/proposals/layoutStepper.vue';
import cardRadioButton from '@/views/components/proposals/cardRadioButton.vue';
import casting_calls_services from '@/services/casting_calls';

export default {
  name: 'stepSelectService',
  components: {
    BRow,
    BCol,
    BButton,
    layoutStepper,
    cardRadioButton,
  },
  data() {
    return {
      selected: null,
      index_actived: 0,
      steps: null,
      casting_call: {}
    }
  },
  computed: {
    has_slug() {
      return this.$route.params.slug;
    }
  },
  created() {
    setTimeout(() => {
      this.getData();
    }, 200);
  },
  methods: {
    getActive(value, index) {
      this.clearActive();
      this.$refs.card_radio_button[index].classList.add('active-class-button');
      this.selected = value;
      this.index_actived = index;
    },
    clearActive() {
      for (let index = 0; index < this.$t('creator.type_of_service').length; index++) {
        this.$refs.card_radio_button[index].classList.remove('active-class-button')
      }
    },
    save() {
      if (this.has_slug) {
        if (this.selected.value !== this.casting_call.content_type) {
          this.$swal({
            title: this.$t('creator.changeTypeWorkBrand'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: this.$t('creator.accept'),
            cancelButtonText: this.$t('creator.cancel'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              const form_data = new FormData();
              form_data.append('content_type', this.selected.value);
              form_data.append('remove_work_types', true)
              casting_calls_services.updateCastingCall(this.casting_call.uuid, form_data).then(() => {
                this.$router.push({ name: 'dashboard-analytics' }).then(() => {
                  this.$router.push({
                    name: 'steps_casting_call',
                    params: {
                      step: 'service-options',
                      slug: this.casting_call.slug
                    } 
                  });
                });
              });
            }
          })
        } else {
          this.$router.push({name: 'preview_casting_call', params: {slug: this.casting_call.slug}});
        }
      } else {
        if (this.steps === null) {
          this.steps = {content_type: this.selected, index_actived: [this.index_actived]}
        } else {
          this.steps.content_type = this.selected;
          this.steps.index_actived[0] = this.index_actived;
        }
        localStorage.setItem('steps', JSON.stringify(this.steps));
        this.$router.push({ name: 'steps_casting_call', params: {step: 'service-options'}})
      }
    },
    getData() {
      if (localStorage.getItem('steps')) {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        this.getActive(this.steps.content_type, this.steps.index_actived[0])
      } else if (this.has_slug) {
        this.casting_call = this.$route.params.casting_call_data;
        const content_type = this.$t('creator.type_of_service').find((type) => type.value === this.casting_call.content_type)
        this.getActive(content_type, this.$t('creator.type_of_service').indexOf(content_type));
      }
    },
  },
}
</script>
<style>
.active-class-button > .card-radion-button-steps {
  background-color: rgba(114, 103, 240, 0.021) !important;
  border: 0.0625rem solid #5582f6; 
}
.active-class-button > .card-radion-button-steps > .background-left-proposal {
  background-color: #5582f6  !important;
}
</style>